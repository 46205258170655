import React from "react";

const DecorationRightSmall = () => {
  return (
    <svg width="1224" height="825" viewBox="0 0 1224 825" fill="none">
      <path
        className="line"
        d="M0 1H1196.43L745.019 822.918"
        stroke="#184179"
      />
      <path className="line" d="M0 20.5L1211 22.5L771 823" stroke="#184179" />
      <path className="line" d="M0 40.5L1223 42.5L792.5 824" stroke="#184179" />
    </svg>
  );
};

export default DecorationRightSmall;
