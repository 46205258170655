import React from "react";
import styled from "styled-components";
import Image from "./Image";
import { mediaMedium } from "./styles/variables";
import { graphql, useStaticQuery } from "gatsby";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  pointer-events: none;
`;

const Decoration = styled.div`
  padding-bottom: 64px;

  @media (${mediaMedium}) {
    position: absolute;
    height: 100%;
    width: calc(200% + (100% * 3 / 12));
    transform: translateX(calc(-100% * 0.5 / 12));
    top: -8%;
    left: 0;
  }
`;

const Display = styled(Image)`
  user-select: none;
`;

const Top = styled.div`
  transform: translateX(-10%);

  @media (${mediaMedium}) {
    max-width: 720px;
    width: 100%;
    position: absolute;
    top: 14%;
    left: 0;
  }
`;

const Bottom = styled.div`
  position: absolute;
  bottom: -40%;
  left: 10%;
  width: 100%;

  @media (${mediaMedium}) {
    max-width: 720px;
    position: absolute;
    bottom: -36%;
    left: 4%;
  }
`;

const PublishersShopsDisplay = () => {
  const Images = useStaticQuery(graphql`
    query shopCommerce {
      top: file(relativePath: { eq: "16-commerce-shop-top.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      bottom: file(relativePath: { eq: "16-commerce-shop-bottom.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Decoration>
        <Top>
          <>
            <Display fluid={Images.top.childImageSharp.fluid} />
          </>
        </Top>
        <Bottom>
          <>
            <Display fluid={Images.bottom.childImageSharp.fluid} />
          </>
        </Bottom>
      </Decoration>
    </Wrapper>
  );
};

export default PublishersShopsDisplay;
