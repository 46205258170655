import React from "react";
import styled from "styled-components";

const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 36px;

  li {
    position: relative;
    &:before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: -24px;
      content: "•";
    }
  }
`;

const List = ({ children }) => {
  return <ListWrapper>{children}</ListWrapper>;
};

export default List;
