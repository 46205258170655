import React from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import { black1, gray4, white } from "../styles/Colors";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import TextHighlight from "../styles/TextHighlight";
import List from "../styles/List";
import DisplayWithOverflow from "../DisplayWithOverflow";
import HeroMetrics from "../HeroMetrics";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import HeroBlueCTA from "../HeroBlueCTA";
import { graphql, Link, useStaticQuery } from "gatsby";
import PublishersShopsDisplay from "../PublishersShopsDisplay";
import AnimatedLineSvg from "../AnimatedLineSvg";
import DecorationRightSmall from "../snippets/DecorationRightSmall";

const Decoration = styled.div`
  width: 1252px;
  left: -58%;
  position: absolute;
  top: 310px;

  @media (max-width: 1980px) {
    max-width: 100%;
  }

  @media (min-width: 1981px) {
    max-width: 100%;
    left: -30vw;
  }
`;

const PublishersIndexDefer = () => {
  const Images = useStaticQuery(graphql`
    query PublishersIndexLazy {
      logoTestimonial: file(relativePath: { eq: "17-new-york-post.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "17-testimonial-publisher-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "17-testimonial-publisher-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      stackMediaDesktop: file(
        relativePath: { eq: "15-stackmedia-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      stackMediaMobile: file(relativePath: { eq: "15-stackmedia-mobile.png" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HeroTwoColumns background={black1} link={true}>
        <PagePadding>
          <HeroContentSnippet
            color={white}
            title="Shops"
            subTitle="Commerce"
            link={{ to: "/publishers/shops/" }}
            buttonVariant="tint"
          >
            <p>
              We build, power, and merchandise your fully-branded Shop so you
              can keep your readers on-site and earn on every sale with{" "}
              <TextHighlight theme={{ color: white, background: black1 }}>
                3-5x higher commissions
              </TextHighlight>{" "}
              than typical affiliate networks.
            </p>
            <div>
              <List>
                <li>
                  Get custom commerce content to convert readers into buyers
                </li>
                <li>
                  Unlock revenue potential with paid ads and shoppable emails
                  managed by Stack
                </li>
                <li>
                  Get expert guidance and exclusive offers from our professional
                  merchandisers
                </li>
              </List>
            </div>
            <p>And so much more.</p>
          </HeroContentSnippet>
        </PagePadding>
        <PublishersShopsDisplay />
      </HeroTwoColumns>

      <HeroTwoColumns soloSpace="padding: 0 0 63px;">
        <div>
          <Decoration>
            <AnimatedLineSvg triggerOffset={-500} svgWidth="100%">
              <DecorationRightSmall />
            </AnimatedLineSvg>
          </Decoration>
        </div>

        <PagePadding>
          <HeroContentSnippet
            title="Affiliate"
            subTitle="Commerce"
            link={{ to: "/publishers/affiliate/" }}
          >
            All the access without the shop. Drive commerce revenue with our
            marketplaces.
          </HeroContentSnippet>
        </PagePadding>
      </HeroTwoColumns>

      <HeroTwoColumns background={gray4}>
        <PagePadding>
          <HeroContentSnippet
            title="Stackmedia"
            subTitle="Advertising"
            link={{ to: "/publishers/stack-media/" }}
          >
            CPC-based advertising solutions. <br /> Earn with every click.
          </HeroContentSnippet>
        </PagePadding>
        <div>
          <DisplayWithOverflow
            animation={true}
            image={[
              {
                fluid: Images.stackMediaDesktop.childImageSharp.fluid,
                alt: "CPC-based advertising solutions. Earn with every click."
              },
              {
                fluid: Images.stackMediaMobile.childImageSharp.fluid,
                alt: "CPC-based advertising solutions. Earn with every click."
              }
            ]}
          />
        </div>
      </HeroTwoColumns>

      <HeroMetrics
        theme={{ width: "100%" }}
        description="Publisher Impressions Driven By Stack's Social Ad Solution Annually"
        number={5.8}
        start={0}
        suffix="B+"
      />

      <HeroTestimonial
        media={Images}
        author="Jackie Goldstein | VP, Commerce @ New York Post"
      >
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          StackCommerce is one of the fastest and easiest ways for a publisher
          to launch and maintain a commerce business. Not to mention, it was
          seamless to integrate branded merchandise in our storefront.
        </Headline32>
      </HeroTestimonial>

      <HeroBlueCTA
        title="LET’S CREATE"
        outline="TOGETHER"
        description="See how Stack can help you grow your commerce and ad revenues."
        link={{ to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }}
      />
    </>
  );
};

export default PublishersIndexDefer;
